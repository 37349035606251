<template>
    <div>
        <v-card-text>
            <v-container grid-list-md>
                <v-layout row>
                    <v-flex xs12 sm6>
                        <v-layout row>
                            <v-flex xs12 sm12 class="font-weight-bold">1. General</v-flex>
                            <v-flex xs4 sm4>Name</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.title }}</v-flex>
                            <v-flex xs4 sm4>OTS short name</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.otsname }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.name2">Other (related) name</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.name2">: {{ buyer.name2 }}</v-flex>
                            <v-flex xs4 sm4>Address</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.address1 }}</v-flex>
                            <v-flex xs4 sm4>Address 2</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.address2 }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.address3">Address 3</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.address3">: {{ buyer.address3 }}</v-flex>
                            <v-flex xs4 sm4>City</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.city }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.postcode">Postcode</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.postcode">: {{ buyer.postcode }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.state">State/Province</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.state">: {{ buyer.postcode }}</v-flex>
                            <v-flex xs4 sm4>Country</v-flex>
                            <v-flex xs8 sm8>: {{ ar_countries[buyer.country_id]  }}</v-flex>
                            <v-flex xs4 sm4>Office Telephone</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.tel }}</v-flex>
                            <v-flex xs4 sm4>Office Telephone</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.tel }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.website">Website</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.website">: {{ buyer.website }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.gstno">Tax ID</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.gstno">: {{ buyer.gstno }}</v-flex>
                            <v-flex xs4 sm4 v-show="buyer.uscc">USCC</v-flex>
                            <v-flex xs8 sm8 v-show="buyer.uscc">: {{ buyer.uscc }}</v-flex>
                            <v-flex xs12 sm12 pr-5 ma-0>
                                <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs12 sm12 class="font-weight-bold">5. OTS Login</v-flex>
                            <v-flex xs4 sm4>User ID</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.userid }}</v-flex>
                            <v-flex xs4 sm4>Password</v-flex>
                            <v-flex xs8 sm8>: {{ buyer.password }}</v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs11 sm5>
                        <v-layout row>
                            <v-flex xs12 sm12 class="font-weight-bold">2. Marketing</v-flex>
                            <v-flex xs4 sm4>Sales Staff</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs4 sm4>Sales Territory</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs4 sm4>Product Segment</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs4 sm4>Port of Discharge</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs12 sm12 pa-0 ma-0>
                                <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs12 sm12 class="font-weight-bold">3. Payment Terms</v-flex>
                            <v-flex xs4 sm4>Direct Sales/Service Sales</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs8 sm8 offset-xs4 offset-sm4>: {{ }}</v-flex>
                            <v-flex xs8 sm8 offset-xs4 offset-sm4>: {{ }}</v-flex>
                            <v-flex xs4 sm4>Warehouse Sales</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs8 sm8 offset-xs4 offset-sm4>: {{ }}</v-flex>
                            <v-flex xs12 sm12 pa-0 ma-0>
                                <v-divider></v-divider>
                            </v-flex>
                            <v-flex xs12 sm12 class="font-weight-bold">4. Operations</v-flex>
                            <v-flex xs4 sm4>Port of Discharge</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs4 sm4>FSC & PEFC Certification</v-flex>
                            <v-flex xs8 sm8>: {{ }}</v-flex>
                            <v-flex xs4 sm4>Buyer Specific Requests</v-flex>
                            <v-flex xs8 sm8>: {{ Customer__doc_setting }}</v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>
    </div>
</template>

<script>
    export default {
        name: "BuyerPreview"
    }
</script>

<style scoped>

</style>